.mobileMenuBlock {
    padding: 6px 0px 6px 0px;
    border-bottom: 0px solid white;
    transition: border-bottom 0.1s linear;
    font-size: 30px;
}

.mobileMenuBlock:hover {
    text-decoration: none;
}

.mobileMenuBlockSelected {
    padding: 6px 0px 0px 0px;
    font-weight: bold;
    border-bottom: 4px solid orange;
    font-size: 30px;
}
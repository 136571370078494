.menuBlock {
    padding: 10px 10px 8px 10px;
    border-bottom: 5px solid white;
    transition: border-bottom 0.1s linear;
}

.menuBlock:hover {
    border-bottom: 5px solid orange;
    text-decoration: none;
}

.menuBlockSelected {
    padding: 10px 10px 8px 10px;
    font-weight: bold;
    border-bottom: 5px solid orange;
}
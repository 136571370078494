@font-face {
  font-family: "Twenty";
  src: url('./fonts/ofont.ru_Garet.ttf');
}

@font-face {
  font-family: "Hero";
  src: url('./fonts/ofont.ru_Hero.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.services:hover  {
  box-shadow: 0 14px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  margin-top: -5px;
  cursor: pointer;
  transition: 0.5s;
}

.accordion:hover{
  background-color: gold;
  transition: 1s;
  animation: col 0.1s forwards;
}

@keyframes col {
  0% {
    color: black;
  }
  100% {
    color: white;
  } 
}

.boxShadow{
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25), 1px 14px 7px 7px rgba(0, 0, 0, 0.22);
}

.reviews{
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25), 1px 14px 7px 7px rgba(0, 0, 0, 0.22);
}

.reviews:hover{
  margin-top: 30px;
  cursor: pointer;
  transition: 1s;
}

.big:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.footerText:hover{
  color: gold;
  transition: 1s;
}